// eslint-disable-next-line no-redeclare,no-unused-vars
function saveAdminSettings(send, callback) {
  send.func = 'admin';
  if (!send.type) send.type = 'option';
  if (send.objectSub) send.key += ucfirst(send.objectSub);
  var pm = {object: 'settings', post: true, send: send};
  if (
    adminFunc == 'global' ||
    inArray(send.object, ['admin', 'adminParameter', 'adminMenu'])
  )
    pm.objectSub = 'settingsGlobal';
  else if (adminFunc == 'industry') pm.objectSub = 'settingsIndustry';
  else if (adminFunc == 'client' || !adminFunc) pm.objectSub = 'settingsClient';
  else return;
  if (callback) pm.callback = callback;
  ajax(pm);
}
